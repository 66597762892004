// extracted by mini-css-extract-plugin
export var caseStudyVideoReview = "d7_l2";
export var caseStudyVideoReview__bgRings = "d7_JM";
export var caseStudyVideoReview__clientImage = "d7_JP";
export var caseStudyVideoReview__container = "d7_JL";
export var caseStudyVideoReview__logo = "d7_JZ";
export var caseStudyVideoReview__name = "d7_J0";
export var caseStudyVideoReview__playWrapper = "d7_JC";
export var caseStudyVideoReview__playWrapper_videoReviewImpact = "d7_JF";
export var caseStudyVideoReview__playWrapper_videoReviewIx = "d7_JK";
export var caseStudyVideoReview__playWrapper_videoReviewPmk = "d7_JH";
export var caseStudyVideoReview__playWrapper_videoReviewRef = "d7_JJ";
export var caseStudyVideoReview__playWrapper_videoReviewTradeaboat = "d7_JD";
export var caseStudyVideoReview__playWrapper_videoReviewTradervs = "d7_JG";
export var caseStudyVideoReview__text = "d7_J1";
export var caseStudyVideoReview__title = "d7_JN";
export var caseStudyVideoReview__videoYoutube = "d7_JY";
export var caseStudyVideoReview__wrapper = "d7_JQ";
export var caseStudyVideoReview__wrapper_videoReviewImpact = "d7_JT";
export var caseStudyVideoReview__wrapper_videoReviewIx = "d7_JX";
export var caseStudyVideoReview__wrapper_videoReviewPmk = "d7_JV";
export var caseStudyVideoReview__wrapper_videoReviewRef = "d7_JW";
export var caseStudyVideoReview__wrapper_videoReviewTradeaboat = "d7_JR";
export var caseStudyVideoReview__wrapper_videoReviewTradervs = "d7_JS";